<template>
    <section class="setup-polls">
        <div class="container-fluid px-4 py-2">
            <div class="row">
                <div class="col-5">
                    <div class="d-flex flex-column h-100 px-4">
                        <h2 class="txt-header my-auto">Buat konten polling</h2>
                        <h4 class="my-auto font-weight-normal text-left">Coba pengalaman polling menggunakan AI Facial Expression</h4>
                    </div>
                </div>
                <div class="col-7">
                    <div class="d-flex flex-column h-100 px-4">
                        <h2 class="txt-header my-auto" v-if="listQuestion != null && listQuestion.length > 0">10 soal pilihan ganda Bahasa Indonesia</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <div class="d-flex flex-column pt-3 px-4 border-separator">
                        <div class="mb-4">
                            <div class="card">
                                <div class="card-body text-left">
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <label type="button" class="w-100">
                                                <div class="d-flex img-wrap">
                                                    <img v-if="images_cover != '-' && images_cover != '' &&  images_cover != null && images_cover != undefined && images_cover != 'undefined'" :src="images_cover"  alt="" class="img-fluid w-100">
                                                    <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-fluid w-100"/>
                                                    <div class="img__description_layer">
                                                        <p class="img__description">Click to change image</p>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="txt-nama-polling">Nama Polling*</label>
                                        <input type="text" class="form-control" id="txt-nama-polling" :class="polling_name == null || polling_name == '' ? 'is-invalid' : ''" v-model="polling_name" />
                                    </div>
                                    <div class="form-group">
                                        <label for="txt-deskripsi-polling">Deskripsi</label>
                                        <textarea :class="polling_description == null || polling_description == '' ? 'is-invalid' : ''" v-model="polling_description" class="form-control" id="txt-deskripsi-polling" rows="4"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Polling Type*</label>
                                        <div>
                                            <button v-for="(value, index) in list_polling" :key="index" type="button" class="btn py-2 px-4 mr-2 mb-2" :class="formData.polling == value ? 'btn-purple': 'btn-grey'" @click="setPolling(value)">{{value}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="d-flex flex-column pt-3 px-4 text-left h-100">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="txt-question">Pertanyaan</label>
                                    <input type="text" class="form-control" id="txt-question" />
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import "element-ui/lib/theme-chalk/index.css";
    import "element-tiptap/lib/index.css";
    import $ from "jquery";
    
    export default {
        data() {
            return {
                formData: {
                    polling: 'Reguler',
                },
                list_polling: [
                    'Reguler',
                    'AI Facial Expression',
                ],
                list_grade: [
                    'Elementary SD',
                    'Middle SMP',
                    'High SMA',
                    'University',
                    'General'
                ],
                list_elementary: [
                    '1 - 2',
                    '3 - 4',
                    '5 - 6'
                ],
                list_notes: [
                    'Selalu gunakan kata sakti Soca dalam memberikan perintah untuk pembuatan soal atau pertanyaan.',
                    'Saat ini AI masih dalam versi beta, sehingga hanya dapat memproses 5-10 pertanyaan.',
                    'Jangan menggunakan kata singkatan dalam memberikan instruksi.'
                ],
                list_ai: [
                    'Algoritma Pemograman',
                    'Persamaan Matematika',
                    'Formula Fisika'
                ],
                listQuestion: [],
                titleMultipleChoices : "Pilihan Ganda",
                titleTrueFalse : "Benar atau Salah",
                titleFillInTheBlank : "Short Answers",
                titleMenjodohkan : "Menjodohkan",
                textOnProcess: 'Tunggu sebentar ya..',
                isProcess: false
            };
        },
        methods: {
            setSideBar() {
                this.$store.commit("IS_SIDEBAR_ACTIVE", false);
            },
            setPolling(value) {
                this.formData.polling = value
            },
        },
        mounted() {
            $("section.setup-polls").parent().addClass("m-0 px-0");
        
            this.setSideBar();
        }
    };
</script>

<style scoped>
    .txt-header {
        text-align: left;
        font-size: 1.5rem;
    }

    .card {
        border-radius: 10px;
        border: 1.18px solid #E5E5E5;
    }

    .card-body {
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: .5rem;
        margin-right: .5rem;
    }

    .btn-purple {
        color: white;
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        border-radius: 25px;
    }

    .btn-grey {
        background-color: #E5E5E5;
        border: 1px solid #E5E5E5;
        border-radius: 25px;
    }

    small {
        color: #585859
    }

    small.hint {
        font-size: 12px;
    }

    small.notes {
        font-size: 10px;
    }

    .btn-process {
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
    }

    .list-ai {
        width: 32%;
    }

    .list-ai .card {
        background-color: #E8E8E8;
        height: 100%;
    }

    .separator {
        width: 1px;
        background-color: #000000;
    }

    .correct-active{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #40BE45;
    }

    .image-url-style{
        width: 6rem;
        height: 6rem;
    }

    .incorrect-active{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #EB5757;
    }
    
    .border-separator {
        border-right: 1px solid #8C8C8C;
    }

    .img-wrap{
        position: relative;
    }

    .img-wrap img{
        border-radius: 10px;
    }

    .img__description_layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        /* transition effect. not necessary */
        transition: opacity .2s, visibility .2s;
    }

    .img-wrap:hover .img__description_layer {
        visibility: visible;
        opacity: 1;
    }

    .img__description {
        transition: .2s;
        transform: translateY(1em);
    }

    .img-wrap:hover .img__description {
        transform: translateY(0);
    }
</style>
  